<template>
  <!-- Page Heading -->

  <!-- <page-header
    :title="'Customers'"
    :hasLink="true"
    :linkTo="'/customers/create'"
    :linkText="'New Customer'"
  /> -->

  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3 pr-2">
        <div class="col-md-5">
          <table-header :title="filters.active == 0 ? 'Archived Customers' : 'Customers'" :hasLink="true"
            :linkTo="'/customers/create'" :linkText="'New Customer'" />
        </div>
        <div class="col-md-7">
          <form class="form-inline float-right">
            <div class="form-group">
              <input v-model="filters.name" class="form-control form-control-sm  mr-1" placeholder="Name" ref="search" />
            </div>
            <div class="form-group">
            </div>
            <div class="form-group ">
              <button type="button" class="btn btn-danger btn-sm mr-1" @click="filters = {}"><i class="fa fa-undo"></i>
                Reset</button>
            </div>
            <div class="form-group ">
              <button v-if="filters.active == false" type="button" v-on:click="pauseEvent"
                v-tooltip.top="'Active Customers List'" class="btn btn-success btn-sm mr-1" @click="filters.active = 1"><i
                  class="fa fa-archive"></i> Active</button>

              <button v-else type="button" v-on:click="pauseEvent" v-tooltip.top="'Archived Customers List'"
                class="btn btn-warning btn-sm mr-1" @click="filters.active = 0"><i
                  class="fa fa-archive"></i> Archived</button>

              <button type="button" @click="download()" class="btn btn-primary btn-sm  mr-2"><i
                  class="fa fa-download"></i>
                Export</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body">
      <DataTable :loading="loading" :value="customers.data" selectionMode="single" @sort="onSort">
        <Column style="padding:4px;" field="company_name" :sortable="true" header="Company Name"></Column>

        <Column field="phone" header="Phone #">
          <template #body="slotProps">
            <a v-bind:href="'tel:' + slotProps.data.phone">{{ slotProps.data.phone }}</a>

            <!-- <span v-if="(slotProps.data.user_contact_details).length>1">
              <button  type="button" v-on:click="showDialog(slotProps.data.company_name, slotProps.data.user_contact_details)" class="btn btn-light ml-2"><i class="fa fa-bars" aria-hidden="true"></i></button>

              <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="display" :style="{width: '45vw'}">
                <template #header>
                  <h5>{{customerName}}</h5>
                </template>
                <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Contact Numbers</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="contact in contactDetails" :key="contact" >
                          <td>
                            {{contact.contact_phone_type == 0 ? contact.contact_landline_number : contact.contact_phone_number}}
                            <div class="text-danger" style="margin-top: -16px; margin-left: 116px; font-weight: 800; color: #a3a3a3; font-size: 10px;">
                              {{contact.contact_phone_type == true ? 'L':'M'}}
                            </div>
                          </td>

                          <td>
                            <button v-tooltip.top="'Add Customer to SMS List'" type="button" v-on:click="showDialog(slotProps.data.company_name, slotProps.data.user_contact_details)" class="btn btn-success ml-2">Add <i class="fa fa-plus" aria-hidden="true"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </Dialog>
            </span> -->

          </template>
        </Column>

        <Column style="padding:4px;" header="Products">
          <template #body="slotProducts">

            <span v-if="slotProducts.data.products">
              <small v-for="productObj in slotProducts.data.products" :key="productObj"
                class="badge badge-primary mr-1">{{ productObj.product ? productObj.product.name : '' }}</small>
            </span>

            <span v-if="(slotProducts.data.products).length < 1">
              <small class="badge badge-danger">No Products yet!</small>
            </span>
          </template>
        </Column>

        <Column style="padding:4px;" header="Servers">
          <template #body="slotRecord">
            <span v-if="slotRecord.data.servers">
              <small v-for="serverObj in slotRecord.data.servers" :key="serverObj" class="badge badge-primary mr-1">
                <router-link class="text-light"
                  :to="{ path: '/customers/' + slotRecord.data.id + '/edit', query: { activeTab: 'servers' }}">
                  {{ serverObj.server.name }}
                </router-link>
              </small>
            </span>

            <span v-if="(slotRecord.data.servers).length < 1">
              <small class="badge badge-danger">No Server</small>
            </span>
          </template>
        </Column>

        <!-- <Column field="rating" header="Rating #">
          <template #body="slotProps">
            <div :class="addClass(slotProps.data)">
              <strong>{{slotProps.data.rating}}</strong>
            </div>
          </template>
        </Column> -->

        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>

        <Column style="padding:4px;" header="Actions">
          <template #body="slotProps">
            <!-- <button v-if="filters.active == false" v-tooltip.top="'Move to active'" v-on:click="performCustomerAction(slotProps.data.id, 1)" class="btn btn-circle btn-success btn-sm mr-1"><i
                class="fa fa-toggle-on" aria-hidden="true"></i></button>
            <button v-else v-tooltip.top="'Move to archive'" v-on:click="performCustomerAction(slotProps.data.id, 0)" class="btn btn-circle btn-warning btn-sm mr-1"><i
                class="fa fa-archive" aria-hidden="true"></i></button> -->
            <button v-on:click="replicateCustomer(slotProps.data.id)" class="btn btn-circle btn-secondary btn-sm mr-1"><i
                class="fa fa-clone" aria-hidden="true"></i></button>
            <router-link class="btn btn-circle btn-primary  btn-sm"
              :to="{ name: 'EditCustomer', params: { id: slotProps.data.id } }"><i class="fas fa-edit"></i></router-link>
            <button v-on:click="deleteCustomer(slotProps.data.id)" class="btn btn-circle btn-danger  btn-sm ml-1"><i
                class="fas fa-trash"></i></button>
          </template>
        </Column>
      </DataTable>
      <div v-if="customers.data.length > 0" class="pagination d-flex justify-content-center">
        <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getCustomers(customers.first_page_url)"
          :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
        <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getCustomers(customers.prev_page_url)"
          :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
        <label>Page {{ pagination.current_page }} of {{ pagination.last_page }} (Total: {{customers.total}})</label>
        <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getCustomers(customers.next_page_url)"
          :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next
        </button>
        <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getCustomers(customers.last_page_url)"
          :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page
        </button>

        <div class="ml-1">
          <select v-model="filters.per_page" class="form-control"
            style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
            <option selected :value="5">5</option>
            <option selected :value="10">10</option>
            <option selected :value="20">20</option>
            <option selected :value="50">50</option>
            <option selected :value="100">100</option>
          </select>
        </div>
      </div>

      <Toast />
      <ConfirmDialog></ConfirmDialog>
    </div>
  </div>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import { eventBus } from '../../eventBus.js';

export default {

  directives: {
    'tooltip': Tooltip
  },

  components: {
    TableHeader,
    DataTable,
    Column,
    ConfirmDialog,
    // Dialog,
    Toast
  },

  data() {
    return {
      filters: {
        per_page: 10
      },
      loading: true,
      customers: [],
      pagination: {},
      ExcelFileName: "customers.xlsx",
      moment: moment,
      totalRows: 0,
      base_url: process.env.VUE_APP_SERVICE_URL,
      customer_url: '',
      search_text: '',
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if(this.search_text != this.filters.name){
              this.getCustomers()
              this.loading = true;
            }
            this.awaitingSearch = false;
            this.search_text = this.filters.name;
          }, 700); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    },
  },

  methods: {
    download() {
      this.loading = true;
      const requestFilters = { ...this.filters };
      requestFilters.type = this.$route.query.type ? this.$route.query.type : "";
      requestFilters.export = true;

      axios
        .get(this.base_url + '/customers', {
          responseType: "blob",
          headers: {
            Authorization:
              `Bearer ` +
              localStorage.getItem("rezo_customers_user"),
          },
          params: requestFilters
        })
        .then((response) => {
          this.loading = false;
          const url = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.ExcelFileName}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: "Error",
            text: "Something Went Wrong!",
          });
          this.loading = false;
        });
    },
    pauseEvent() {
      if (this.filters.active) {
        this.color = '#2e59d9';
        this.loading = true;
      }
      else {
        this.color = '#e02d1b';
        this.loading = true;
      }
    },
    
    onSort(event) {
        this.filters.sort_field = event.sortField; // e.g., "company_name"
        this.filters.sort_order = event.sortOrder === 1 ? 'asc' : 'desc'; // 1 for ascending, -1 for descending
        this.getCustomers();
    },

    getCustomers: function (page_url) {
      this.loading = true;
      let vm = this;
      page_url = page_url || this.base_url + '/customers'

      this.filters.type = this.$route.query.type ? this.$route.query.type : "";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios.get(page_url, { headers, params: this.filters })
        .then((response) => {
          this.loading = false;
          this.customers = response.data.customers;
          vm.makePagination(response.data)
          this.$refs.search.focus();
          this.loading = false;
        });
    },

    makePagination: function (data) {

      let pagination = {
        current_page: data.customers.current_page,
        last_page: data.customers.last_page,
        next_page_url: this.app_base_url + '/customers?page=' + (data.customers.current_page + 1),
        prev_page_url: this.app_base_url + '/customers?page=' + (data.customers.current_page - 1),
      }

      this.pagination = pagination;
    },

    deleteCustomer: function (customerId) {

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          axios
            .delete(this.base_url + "/customers/" + customerId, {
              headers: headers,
            })
            .then((response) => {

              this.getCustomers();
              this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
              console.log("Delete customer basic data: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
              this.$notify({
                type: "error",
                title: "Something Went Wrong",
                text: "Error!",
              });
            });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },

    replicateCustomer: function (customerid) {
      var data = {
        id: customerid,
      };

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to make an exact copy of it?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          axios
            .post(this.base_url + "/replicate/customer", data, { headers: headers })
            .then((response) => {
              this.getCustomers();
              this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record clone successfully!', life: 2000 });
              console.log("Delete customer basic data: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
              this.$notify({
                type: "error",
                title: "Something Went Wrong",
                text: "Error!",
              });
            });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },
    performCustomerAction(customerid, active) {
      var data = {
        id: customerid,
        active: active
      };

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: `Are you sure you want to ${active ? 'activate' : 'archive'} it?`,

        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          axios
            .post(this.base_url + "/perform_customer_action", data, { headers: headers })
            .then((response) => {
              this.getCustomers();
              this.$toast.add({ severity: 'success', summary: 'Deleted', detail: `Record ${active ? 'activated' : 'archived'} successfully!`, life: 2000 });
              console.log("Delete customer basic data: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
              this.$notify({
                type: "error",
                title: "Something Went Wrong",
                text: "Error!",
              });
            });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },
  },

  mounted() {
    this.$refs.search.focus();

    //Excel export with product name
    var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
    axios.get(this.base_url + '/product', { headers })
      .then(response => {

        response.data.products.forEach(element => {
          if (this.$route.query.type == element.id) {
            this.ExcelFileName = "Customers (" + element.name + ").xlsx"
          }
        });
      })
    //Excel export with product name
  },
  created() {
    this.getCustomers();
    eventBus.on('update-filters', (newFilters) => {
      this.filters = newFilters;
    });
  },
};
</script>

<style scoped>
.no-rate {
  color: red;
}

.veryLow-rate {
  color: red;
}

.low-rate {
  color: rgb(206, 160, 22);
}

.medium-rate {
  color: rgb(22, 123, 206);
}

.high-rate {
  color: rgb(53, 190, 53);
}

.veryHigh-rate {
  color: rgb(53, 190, 53);
}

::v-deep(.p-datatable-loading-icon) {
    color: white;
}
</style>